import React, { useState, useEffect } from 'react'
import '../../pages/style.css';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import Skills from './Skills';
import climbingimage from '../../Assets/climbing.jpg';
import { masteredLanguages, mlExpertises, dailyTechTools, softSkills } from './details';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BadgeList from '../Badge';
import { FaInfoCircle } from "react-icons/fa";

function renderTooltip(props) {
    return (
        <Tooltip id="button-tooltip" {...props}>
            Click on the badges bellow to see more details.
        </Tooltip>
    );
}

function Aboutpage() {
    const [expand, updateExpanded] = useState(false);
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            const title = document.querySelector('.section-title-about');
            const titlePosition = title.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 2;
            if (titlePosition < screenPosition) {
                // console.log('visible');
                title.classList.add('visible-title');
            } else {
                title.classList.remove('visible-title');
                // console.log('not visible');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <div className='aboutpagebackground'>
            <h1 className='section-title-about'>About</h1>
            <Container>
                <Row className='textbackground'>
                    <h3 className='aboutmetext'>➡️ About me</h3>
                    <p className='aboutdetails'>I am a <b>Freelance Senior Machine Learning Engineer</b> currently working for <a href="https://digital.decathlon.net">Decathlon Digital</a>.
                         <br /><br />
                        With 3+ years of experience (3 years as Data Scientist at <a href="https://artefact.com/">Artefact</a>),
                        I have developed a strong expertise in <i>deploying machine learning models</i>, <i>forecasting</i> and <i>generative AI</i>, with a foot in <i>computer vision</i>.
                        <br /><br />
                        I have the strong conviction that every data science project should implement software engineering best practices to make collaboration as smooth and efficient as possible.
                    </p>
                </Row>
                <br />
                <Row className='textbackground'>
                    <div style={{ display: 'flex' }}>
                        <h3 className='aboutmetext'>🛠 My skill set</h3>
                        &nbsp;&nbsp;
                        <span className="d-inline-block">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <i style={{ cursor: 'pointer', color: '#7AA2E3', fontSize: '24px' }}><FaInfoCircle /></i>
                            </OverlayTrigger>
                        </span>
                    </div>
                    {/* TODO: add soft skills */}
                    <br />
                    <br />
                    <h4><b>Mastered languages</b></h4>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <BadgeList badges={masteredLanguages} />
                    </div>
                    <br />
                    <br />
                    <h4><b>Main machine learning expertises</b></h4>
                    <BadgeList badges={mlExpertises} />
                    <br />
                    <br />
                    <h4><b>Daily tech tools</b></h4>
                    <BadgeList badges={dailyTechTools} />
                    <br />
                    <br />
                    <h4><b>Soft skills</b></h4>
                    <BadgeList badges={softSkills} />
                </Row>
                <br />
                <Row className='textbackground'>
                    <h3 className='aboutmetext'>🏆 Notable achievements</h3>
                    <br />
                    <br />
                    <p style={{ fontSize: '1.15em' }}>
                        &#8226; <b>Open-source contribution</b> to the repository <a href="https://github.com/ultralytics/ultralytics/pull/6227">Ultralytics</a> (21k + ⭐️).<br />
                        &#8226; <b>Certified</b> by <b>Google Cloud</b>: <a href="https://cloud.google.com/learn/certification/machine-learning-engineer">Professional Machine Learning Engineer</a>.<br />
                        &#8226; Taught <b>Data Science</b> & <b>MLOPs</b> best practices at top engineering schools (X-HEC, CentraleSupélec, École des Ponts ParisTech, Arts et Métiers).
                    </p>
                </Row>
                <br />
                <Row className='textbackground'>
                    <h3 className='aboutmetext'>👨‍💻 Missions</h3>
                    <Nav.Item style={{ marginLeft: '3px', width: 'auto' }}>
                        <Nav.Link
                            as={Link}
                            to="/projects"
                            style={{ width: 'fit-content' }}
                            onClick={() => { updateExpanded(false); scrollToSection('projects'); }}
                        >
                            <button className='btn btn-primary' style={{ width: '200px', fontSize: '1.15em' }}>👉 Missions details</button>
                        </Nav.Link>
                    </Nav.Item>
                </Row>
            </Container>
        </div >
    )
}

export default Aboutpage
