import React from 'react';
import '../pages/style.css'; // Ensure this file contains the necessary styles
import logobnp from '../Assets/logobnp.png';
import logofnacdarty from '../Assets/logofnacdarty.png';
import logofortenova from '../Assets/logofortenova.png';
import logokhimod from '../Assets/logokhimod.png';
import logoleroymerlin from '../Assets/logoleroymerlin.png';
import logomontblanc from '../Assets/logomontblanc.png';
import logorichemont from '../Assets/logorichemont.png';
import logosephora from '../Assets/logosephora.png';
import logodecathlon from '../Assets/logodecathlon.png';
import logoadeo from '../Assets/logoadeo.png'
import logoiwc from '../Assets/logoiwc.png'

function LogoAnimation() {
    return (
        <div className="logo-animation-container">
            <img src={logodecathlon} alt="Decathlon Logo" className="animated-logo" />
            <img src={logobnp} alt="BNP Logo" className="animated-logo" />
            <img src={logokhimod} alt="Khimod Logo" className="animated-logo" style={{ maxHeight: '35px', marginTop: 'auto', marginBottom: 'auto', display: 'block' }} />
            <img src={logosephora} alt="Sephora Logo" className="animated-logo" />
            <img src={logoleroymerlin} alt="Leroy Merlin Logo" className="animated-logo" />
            <img src={logoadeo} alt="Adeo Logo" className="animated-logo" />
            <img src={logofnacdarty} alt="Fnac Darty Logo" className="animated-logo" style={{ maxHeight: '25px', marginTop: 'auto', marginBottom: 'auto', display: 'block' }} />
            <img src={logomontblanc} alt="Montblanc Logo" className="animated-logo" style={{ maxHeight: '50px', marginTop: 'auto', marginBottom: 'auto', display: 'block' }} />
            <img src={logoiwc} alt="IWC Logo" className="animated-logo" style={{ maxHeight: '50px', marginTop: 'auto', marginBottom: 'auto', display: 'block' }} />
            <img src={logorichemont} alt="Richemont Logo" className="animated-logo" style={{ maxHeight: '20px', marginTop: 'auto', marginBottom: 'auto', display: 'block' }} />
            <img src={logofortenova} alt="Fortenova Logo" className="animated-logo" />
        </div>
    );
}


export default LogoAnimation;
