import React, { useState } from 'react';
import { Badge, Modal, Button } from 'react-bootstrap';

function BadgeCustom({ title, detailedContent, textColor, bgColor }) {
    const [showModal, setShowModal] = useState(false);

    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <>
            <Badge onClick={handleOpen} pill bg="" className="me-2" style={{ cursor: 'pointer', color: textColor, backgroundColor: bgColor || "#7AA2E3" }}>
                {title}
            </Badge>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{detailedContent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
function BadgeList({ badges }) {
    return (
        <div>
            {badges.map((badge, index) => (
                <BadgeCustom
                    key={index}
                    title={badge.title}
                    detailedContent={badge.detailedContent}
                    bgColor={badge.bgColor}
                    textColor={badge.textColor}
                />
            ))}
        </div>
    );
}
export default BadgeList;
