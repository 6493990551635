import React, { useState } from 'react'
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../Homepage/Text'
import {
  AiFillGithub,
  AiFillMediumCircle
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import LogoAnimation from '../LogoAnimation';

function Home() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  return (
    <div className='homepagebackground'>
      <Container>
        <Row>
          <Col md={7}>
            <h2 className='headtext'>Hello <span className='wave'>👋 </span></h2>
            <h2 className='nametext'>I'm Luca Serra</h2>
            <span></span>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h2 className='nametext'>Freelancer - Senior </h2>
              <h2 className='nametext'>&nbsp;</h2>
              {isDesktop && <Text />}
            </div>
            {!isDesktop && <h2 className='nametext'>ML Engineer</h2>}
            {/* TODO: don't apply animation on mobile */}
            <button
              onClick={() => {
                window.open("https://github.com/luca-serra");
              }}
              className='socailmediabtn'><AiFillGithub className='icon' /></button>
            <button onClick={() => {
              window.open("https://www.linkedin.com/in/luca-serra/");
            }}
              className='socailmediabtn'><FaLinkedinIn className='icon' /></button>
            <button onClick={() => {
              window.open("https://medium.com/@luca.serra");
            }}
              className='socailmediabtn'><AiFillMediumCircle className='icon' /></button>
            <br />
            <br />
            <br />
            <h2 className='nametext' style={{ marginTop: '20px', marginBottom: '20px' }}>Companies I've worked with:</h2>
            <div className='logo-animation-container-mobile'>
              {Array.from({ length: 10 }).map((_, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <LogoAnimation />
                  <p style={{ marginTop: '20px', marginBottom: '20px', fontSize: '1.2rem', width: '200px', marginRight: '70px' }}>(More details below ⬇️)</p>
                </div>
              ))}
            </div>
          </Col>

          {/* <Col md={5}>
            <div className="imagedeveloper">
            </div>
          </Col> */}
          {/* // TODO: add image */}
        </Row>
      </Container>
    </div>
  )
}

export default Home
