import React from 'react'
import Header from '../components/Header/Header'
// import Projectlist from '../components/Projectlist/Projectlist'
import Projectpage from '../components/Projectpage/Projectpage'

function Project() {
  return (
    <div id="projects">
      <Header />
      <Projectpage />
    </div>
  )
}

export default Project
