export const masteredLanguages = [
    {
        title: 'Python',
        detailedContent: '7+ years of experience using Python. Used in every data science missions I worked on.',
        bgColor: '#FFAF45'
    },
    {
        title: 'SQL',
        detailedContent: <p>7+ years of experience using SQL. I know the most useful concepts (JOINs, CTEs, aggregations, window functions...) and use templating for complex queries (<a href="https://palletsprojects.com/p/jinja/">Jinja</a>)</p>,
        bgColor: '#FFAF45'
    },
    {
        title: 'Bash',
        detailedContent: '5+ years of experience using Bash scripting and Linux OS (file operations, text processing, permissions, scripting...)',
        bgColor: '#FFAF45'
    },
];
export const mlExpertises = [
    {
        title: 'MLOPs',
        detailedContent: <p>Worked on several continuous training uses cases.
            I master <b>Google Cloud Platform</b> and its different services (Vertex pipelines, Cloud Functions, Cloud Build, GCE, GAE...)<br /><br />
            I try my best to always apply <i>code best practices</i> (e.g: <i>CI</i> / <i>pre-commit hooks</i> with <i>linting</i>, <i>formatting</i>, <i>import sorting</i> and <i>unit tests</i>) in every project I work on and built the reference repository template for data science projects at <a href="https://artefact.com/">Artefact</a>.
        </p>,
        bgColor: "#A1C398"
    },
    {
        title: 'Generative AI',
        detailedContent: <p>Implemented multiple RAGs (Retrieval Augmented Generation) in a mission. <br />
            I follow thoroughly the latest improvements regarding LLMs (Large Language Models), RAG optimizations and I use <a href="https://www.langchain.com">Langchain</a> to code these solutions.</p>,
        bgColor: "#A1C398"
    },
    {
        title: 'Forecasting',
        detailedContent: <p>Worked on multiple forecasting missions, with different modelization strategies (autoregressive, boosting, deep learning...). <br /><br />
            I also designed a forecasting formation at Artefact and taught forecasting for the Arts et Métiers engineering school.</p>,
        bgColor: "#A1C398"
    },
    {
        title: 'Computer Vision',
        detailedContent: <p>Used multiple libraries for an object detection use case (<a href="https://github.com/facebookresearch/detectron2">detectron2</a>, <a href="https://github.com/ultralytics/ultralytics">YOLOv8</a>).<br /><br />
            Contributed to the open-source repository <a href="https://github.com/ultralytics/ultralytics/pull/6227">Ultralytics</a>.</p>,
        bgColor: "#A1C398"
    },
];

export const dailyTechTools = [
    {
        title: 'Git',
        detailedContent: <p>Used git in every projects I worked on with different data science teams (Github and Gitlab).<br /><br />
            Adept of the trunk-based development, I like to include in the collaboration process: small and regular PR/MR ; best practices enforced thanks to a CI (code style and unit tests) and pre-commit hooks.
        </p>,
        bgColor: "#7AA2E3"
    },
    {
        title: 'Docker',
        detailedContent: <p>Essential tool to prepare and package apps for production.<br /><br />
            Used Docker to build images for different use cases (images for Vertex pipelines components ; images for Streamlit web apps deployed on Google App Engine...)
        </p>,
        bgColor: "#7AA2E3"
    },
    {
        title: 'Google Cloud Platform',
        detailedContent: <p>Experienced in <b>Google Cloud Platform</b> and its different services
            (<i>Vertex pipelines</i>, <i>Cloud Functions</i>, <i>Cloud Build</i>, <i>Artifact Registry</i>, <i>Compute Engine</i>, <i>App Engine</i>, <i>Cloud Scheduler</i>...)<br /><br />
            Certified by Google Cloud: <a href="https://cloud.google.com/learn/certification/machine-learning-engineer">Professional Machine Learning Engineer</a>.</p>,
        bgColor: "#7AA2E3"
    },
];

export const softSkills = [
    {
        title: 'Product Management / Delivery',
        detailedContent: <p>As a Senior ML Engineer & Data Scientist, I am used to leading projects from conception to deployment, ensuring that all aspects of product management and delivery are handled efficiently. <br /><br />
            My experience includes: defining project scopes, setting timelines, managing resources, and maintaining communication with all stakeholders. <br /><br />
            I apply agile methodologies to adapt to changes swiftly and to optimize the delivery process for the best possible outcome. <br /><br />
            In my experiences, I had multiple occasions to work on very short timelines (see missions: Sephora, Khimod, Fortenova...) and successfully deliver a product that met the client's expectations.
        </p >,
        bgColor: "#912BBC"
    },
    {
        title: 'Collaborative Work / Knowledge Sharing',
        detailedContent: <p>Throughout my career, I have worked in various teams, both as a leader and as a member. My roles have required me to foster environments that encourage open communication, mutual respect, and shared learning. <br /><br />
            I have consistently contributed to knowledge bases, conducted workshops, and mentored junior team members to ensure collective growth.
            My commitment to collaborative work has not only led to successful project outcomes but also to the creation of a supportive and inclusive team culture. <br /><br />
            At Artefact, I created the initiative "<i>Productivi'tips</i>": a monthly session of tips sharing to boost data scientist productivity (e.g. VSCode extensions, useful apps, code practices, bash aliases...)
        </p>,
        bgColor: "#912BBC"
    },
    {
        title: 'Communication / Pedagogy',
        detailedContent: <p>As a senior profile, I know that the success in a mission depends strongly on communication, to make collaboration seemless.
            Being able to communicate in a MECE way is also very important to lead workshops with business teams to identify the needs and possible solutions.<br /><br />
            Pedagogy is also something I value and had the opportunity to develop: working or coaching several junior profiles on missions or internally, but also teaching in French Grandes Ecoles:<br />
            &#8226; MLOPs @ X-HEC<br />
            &#8226; Data Science for Business @ Ponts et Chaussées<br />
            &#8226; Viral Marketing @ CentraleSupélec<br />
            &#8226; Introduction to forecasting @ Arts et Métiers<br />
        </p>,
        bgColor: "#912BBC"
    },
];
// Soft skills: 1. Product management & delivery 2. Collaborative work & knowledge sharing 3. Communication & pedagogy
