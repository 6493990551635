import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import '../../pages/style.css';


function NavLinkItem({ to, onClick, children }) {
  return (
    <Nav.Item>
      <Nav.Link as={Link} to={to} onClick={onClick} style={{
        backgroundColor: 'rgba(128, 128, 128, 0.1)',
        borderRadius: '20px'
      }}>
        {children}
      </Nav.Link>
    </Nav.Item >
  );
}

function Header() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const openResume = () => {
    // Assuming you have a `resume.pdf` file in the `public` folder
    window.open(process.env.PUBLIC_URL + '/Luca_Serra_resume.pdf', '_blank');
  };

  // function scrollHandler() {
  //   if (window.scrollY >= 20) {
  //     updateNavbar(true);
  //   } else {
  //     updateNavbar(false);
  //   }
  // }

  // window.addEventListener("scroll", scrollHandler);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Navbar expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}>


      {/* <Navbar.Brand className='logotext' as={Link} to='/'>
        <div className='logo'></div>
      </Navbar.Brand> */}

      <Navbar.Toggle className='navbar-toggler' aria-controls="responsive-navbar-nav"
        onClick={() => {
          updateExpanded(expand ? false : "expanded");
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav" className='responsive-navbar'>

        <Nav className="ms-auto" defaultActiveKey="#home">
          <NavLinkItem to="/" onClick={() => { updateExpanded(false); scrollToSection('home'); }}>Home</NavLinkItem>
          &nbsp;
          <NavLinkItem to="/about" onClick={() => { updateExpanded(false); scrollToSection('about'); }}>About</NavLinkItem>
          &nbsp;
          <NavLinkItem to="/projects" onClick={() => { updateExpanded(false); scrollToSection('projects'); }}>Projects</NavLinkItem>
          &nbsp;
          <NavLinkItem to="/" onClick={openResume}>Resume</NavLinkItem>
          &nbsp;
          <NavLinkItem to="/contact" onClick={() => { updateExpanded(false); scrollToSection('contact'); }}>Contact</NavLinkItem>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
}

export default Header;
