import React, { useState, useEffect } from 'react'
import '../../pages/style.css';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap'
import { missions } from './details';
import BadgeList from '../Badge';
import { Button, Badge } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';

const TitleDescriptionRow = ({ title, logo, date, industry, location, mlExpertises, technologies, client, smallDescription, longDescription, logoHeight }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Row className='textbackground'>
            <h3>
                <img src={logo} alt="Logo" style={{ height: logoHeight || '1.3em', verticalAlign: 'middle' }} />
                &nbsp;{title} <span style={{ fontSize: '0.7em' }}>({date})</span>
                &nbsp;
                <Badge pill bg="" className="me-2" style={{ cursor: 'pointer', backgroundColor: "#7AA2E3", fontSize: '0.6em' }}>
                    {industry}
                </Badge>
                <span style={{ fontSize: '0.7em', float: 'right' }}><FaMapMarkerAlt /> <i>{location}</i></span>
            </h3>
            <h3>
                {mlExpertises.map((expertise, index) => (
                    <Badge key={index} pill bg="" className="me-2" style={{ cursor: 'pointer', backgroundColor: "#A1C398", fontSize: '0.6em' }}>
                        {expertise}
                    </Badge>
                ))}
                {technologies.map((techno, index) => (
                    <Badge key={index} pill bg="" className="me-2" style={{ cursor: 'pointer', backgroundColor: "#FFC374", fontSize: '0.6em' }}>
                        {techno}
                    </Badge>
                ))}
            </h3>
            <p><i>{client}</i></p>
            {!expanded && <p>{smallDescription}</p>}
            {expanded && <p>{longDescription}</p>}
            <Button variant="primary" onClick={() => setExpanded(!expanded)}>
                {expanded ? 'Show Less 🔼' : 'Show More 🔽'}
            </Button>
        </Row>
    );
};
// TODO: include missions length


function Projectpage() {
    useEffect(() => {
        const handleScroll = () => {
            const title = document.querySelector('.section-title-project');
            const titlePosition = title.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 2;
            if (titlePosition < screenPosition) {
                console.log('visible');
                title.classList.add('visible-title');
            } else {
                title.classList.remove('visible-title');
                console.log('not visible');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <div className='aboutpagebackground'>
            <h1 className='section-title-project'>Projects</h1>
            <Container>
                {missions.map((mission, index) => (
                    <div>
                        <TitleDescriptionRow
                            {...mission}
                        />
                        <br />
                    </div>
                ))
                }
            </Container>
        </div >
    )
}

export default Projectpage;
