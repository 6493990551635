import React, { useEffect } from 'react'
import '../../pages/style.css';
import { Container } from 'react-bootstrap'

function Contactpage() {
  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector('.section-title-contact');
      const titlePosition = title.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 2;
      if (titlePosition < screenPosition) {
        title.classList.add('visible-title');
      } else {
        title.classList.remove('visible-title');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className='contactbackground'>
      <h1 className='section-title-contact'>Contact</h1>
      <Container>
        <p className='contactpara'>
          You can contact me on LinkedIn or send me an email if you have any question or want to discuss future opportunities.
        </p>
        <button
          className='contactbtn'
          onClick={() => {
            window.open("https://www.linkedin.com/in/luca-serra/"); // TODO: link to Linkedin
          }}
        >Contact me on LinkedIn</button>
        <p></p>
        <button
          className='contactbtn'
          onClick={() => {
            window.location = "mailto:luca.serra@hotmail.fr"; // TODO: link to email
          }}
        >Send me an email</button>
      </Container>
    </div>
  )
}
// TODO: add freelance websites

export default Contactpage
