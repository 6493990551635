import React from 'react'
import Contactpage from '../components/Contactpage/Contactpage'
import Header from '../components/Header/Header'

function Contact() {
  return (
    <div id="contact">
      <Header />
      <Contactpage />
    </div>
  )
}

export default Contact
