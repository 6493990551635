import React, { useState } from 'react'
import Popup from './Popup'
import { Container, Row, Col } from 'react-bootstrap'

function Skills(props) {
  const [isShown, setIsShown] = useState(false);
  return (
    <div>
      <li className='skill-item'
        onClick={() => isShown ? setIsShown(false) : setIsShown(true)}
      >{props.title}</li>
      {
        isShown && (
          <div
          // onMouseLeave={() => setIsShown(false)}
          >
            <Popup title={props.popupTitle} text={props.popupText} image={props.image} />
          </div>
        )}
    </div>
  )
}


export default Skills